.dispatch-incident_list__actions {
    color: var(--nds-color-semantic_primary_500);
    font: var(--nc-t-body_medium);
    cursor: pointer;
}
.dispatch-incident_list__actions__flyover {
    min-width: 200px;
}


.dispatch-incident_list__message_body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--nds-color-core_white);
    width: 400px;
    padding: var(--nds-l-200);
}

.dispatch-incident_list__incident_item {
    opacity: 1;
    padding: var(--nds-l-300) 0;
    border-bottom: 1px solid var(--nds-color-core_grey_200);
    font: var(--nc-t-body_regular);
}
.dispatch-incident_list__incident_item--closed {
    opacity: 0.45;
}
.dispatch-incident_list__incident_item:last-of-type {
    border-bottom-width: 0;
}

.dispatch-incident_list__label_padding_override {
    padding-left: var(--nds-l-utilities_50)!important; 
    padding-right: var(--nds-l-100)!important;
}