.dispatch-session {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        "navbar"
        "form";
    height: 100vh;
}

.dispatch-session__navbar {
    grid-area: navbar;
    position: sticky;
    top: 0;
    background: var(--nds-color-core_white);
    z-index: 10000;
}

.dispatch-session__navbar__loading {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10001;
}

.dispatch-session__form {
    grid-area: form;
}

.dispatch-session__content {
    display: none;
    grid-area: content;
}

@media screen and (min-width: 875px) {
    .dispatch-session {
        grid-template-columns: minmax(500px, 1fr) 2.25fr;
        grid-template-areas:
            "navbar navbar"
            "form content";
    }

    .dispatch-session__form {
        border-right: 1px solid var(--nds-color-core_grey_200);
        overflow: hidden;
    }

    .dispatch-session__content {
        display: block;
        overflow-y: auto;
    }
}
