.dispatch-incident_form_wizard {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.dispatch-incident_form_wizard__body {
    flex: 1 1 100vh;
    padding: var(--nds-l-200);
    overflow-y: auto;
}

.dispatch-incident_form_wizard__action {
    flex: 0;
    padding: var(--nds-l-200);
    border-top: 1px solid var(--nds-color-core_grey_300);
}
