.dispatch-incident_form_summary__description {
    font: var(--nc-t-body_light);
    color: var(--nc-t-grey_900);
    padding: var(--nds-l-utilities_50) var(--nds-l-100);
    border-left: 2px solid var(--nds-color-core_grey_900);
}

.dispatch-incident_form_summary__location {
    border: 1px solid var(--nds-color-core_grey_200);
    border-radius: var(--nds-l-utilities_50);
    background: var(--nds-color-core_white);
    padding: var(--nds-l-200);
    display: flex;
}

.dispatch-incident_form_summary__location__line_1 {
    align-self: center;
}