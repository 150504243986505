.dispatch-form_extension {
    border: 1px solid var(--nds-color-core_grey_200);
    border-radius: var(--nds-l-utilities_50);
    padding: var(--nds-l-200);
}

.dispatch-form_extension__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dispatch-form_extension__body {
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s;
}
.dispatch-form_extension__body--open {
    max-height: 2000px;
}